
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class AppMetricCard extends Vue {
  @Prop() readonly metric!: {
    title: string
    value: number
    change: number
    unit: string
  }

  get rateBgColor() {
    return this.metric.change > 0 ? "#F5FFF5" : "#FFF7F7"
  }

  get rateIcon() {
    return this.metric.change > 0
      ? "la-long-arrow-alt-down la-rotate-by"
      : "la-long-arrow-alt-down  la-rotate-by"
  }
}
