
import { Component, Vue } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import AppMetricCard from "@/components/talent/AppMetricCard.vue"
import PeralteeClaimDetail from "@/components/talent/PeralteeClaimDetail.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"
import {
  AllJobTitlesQuery,
  ConsultantPeralteeClaim,
  ConsultantPeralteeClaimFragmentFragment,
  ConsultantPeralteeClaimsQuery,
  ConsultantPeralteeClaimsStatistics,
  ConsultantPeralteeStatisticsQuery,
} from "@/gql"

@Component({
  apollo: {
    peralteeStatistics: {
      query: ConsultantPeralteeStatisticsQuery,
      update: (data) => {
        return data?.consultantPeralteeClaimsStatistics || null
      },
    },
  },
  components: {
    AppPage,
    AppMetricCard,
    PeralteeClaimDetail,
    UserAvatar,
  },
})
export default class PeralteePoints extends Vue {
  readonly peralteeClaimsQuery = ConsultantPeralteeClaimsQuery
  readonly peralteeStatisticsQuery = ConsultantPeralteeStatisticsQuery
  readonly allJobTitlesQuery = AllJobTitlesQuery

  showClaimDetail = false
  selectedClaim: ConsultantPeralteeClaimFragmentFragment | null = null
  isApproving = false
  isRejecting = false
  peralteeStatistics: ConsultantPeralteeClaimsStatistics | null = null
  actionType: string | null = null
  isRejectSuccess = false

  readonly headers = [
    {
      text: "Foresighter",
      align: "start",
      value: "consultant",
      sortable: false,
      custom: true,
      width: "20%",
    },
    {
      text: "Peraltee Claim",
      value: "supportService.name",
      custom: true,
      width: "20%",
    },
    {
      text: "Details",
      value: "description",
      sortable: false,
      custom: true,
    },
    {
      text: "Points",
      value: "supportService.points",
      sortable: false,
      custom: true,
    },
    {
      text: "Status",
      value: "state",
      sortable: false,
      custom: true,
    },
    {
      text: "Comment",
      value: "comment",
      sortable: false,
      custom: true,
      width: "20%",
    },
  ]

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  gotoDetailPage(item: ConsultantPeralteeClaim) {
    this.selectedClaim = item
    this.showClaimDetail = true
    this.actionType = null
  }

  setActionType(type: string) {
    this.actionType = type
  }

  closeClaimDetail() {
    this.showClaimDetail = false
    this.selectedClaim = null
  }

  get peralteeMetrics() {
    return [
      {
        title: "All",
        value: this.peralteeStatistics?.total ?? 0,
        change: 0,
        unit: null,
      },
      {
        title: "Claims Pending",
        value: this.peralteeStatistics?.pending?.count ?? 0,
        change: this.peralteeStatistics?.pending?.change.count ?? 0,
        unit: null,
      },
      {
        title: "Claims Approved",
        value: this.peralteeStatistics?.approved?.count ?? 0,
        change: this.peralteeStatistics?.approved?.change.count ?? 0,
        unit: null,
      },
      {
        title: "Claims Rejected",
        value: this.peralteeStatistics?.rejected?.count ?? 0,
        change: this.peralteeStatistics?.rejected?.change.count ?? 0,
        unit: null,
      },
    ]
  }

  refetchStatistics() {
    this.$apollo.queries.peralteeStatistics.refetch()
  }
}
