
import {
  ApproveConsultantPeralteeClaimMutation,
  ApproveConsultantPeralteeClaimMutationMutation,
  ConsultantPeralteeClaim,
  ConsultantPeralteeClaimFragmentFragment,
  ConsultantPeralteeClaimsQuery,
  RejectConsultantPeralteeClaimMutation,
  RejectConsultantPeralteeClaimMutationMutation,
} from "@/gql"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class PeralteeClaimDetail extends Vue {
  readonly peralteeClaimsQuery = ConsultantPeralteeClaimsQuery

  @Prop() claim!: ConsultantPeralteeClaim
  @Prop() approving!: boolean
  @Prop() rejecting!: boolean

  form = {
    id: this.claim.id,
    comment: "",
  }

  selectedClaim: ConsultantPeralteeClaimFragmentFragment | null = null
  isApproving = false
  isRejecting = false
  actionType: string | null = null
  isRejectSuccess = false

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  setActionType(type: string) {
    this.actionType = type
  }

  @Watch("claim", { immediate: true })
  onClaimChange() {
    if (this.claim) {
      this.form.id = this.claim.id
    }
  }

  async approvePeralteeClaim() {
    this.isApproving = true

    // Mutate
    const result = await this.mutate<ApproveConsultantPeralteeClaimMutationMutation>({
      mutation: ApproveConsultantPeralteeClaimMutation,
      variables: {
        ...this.form,
      },
      done: () => {
        this.isApproving = false
        this.$emit("refetch")
      },
      refetchQueries: [
        {
          query: this.peralteeClaimsQuery,
        },
      ],
    })

    if (result.data && !result.data.approveConsultantPeralteeClaim.error) {
      this.addSuccess(`Claim approved successfully`)
      this.actionType = null
      this.onSaveComplete()
    } else {
      this.actionType = null
    }
  }

  async rejectPeralteeClaim() {
    this.isRejecting = true
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) {
      this.isRejecting = true
      return this.addError("Reason is required")
    }

    // Mutate
    const result = await this.mutate<RejectConsultantPeralteeClaimMutationMutation>({
      mutation: RejectConsultantPeralteeClaimMutation,
      variables: {
        ...this.form,
      },
      done: () => {
        this.isRejecting = false
        this.isRejectSuccess = true
        this.$emit("refetch")
      },
      refetchQueries: [
        {
          query: this.peralteeClaimsQuery,
        },
      ],
    })

    if (result.data && !result.data.rejectConsultantPeralteeClaim?.error) {
      this.addSuccess(`Claim rejected successfully`)
      this.actionType = null
      this.onSaveComplete()
    } else {
      this.actionType = null
    }
  }

  cancelReject() {
    this.actionType = null
  }

  onSaveComplete() {
    this.$emit("close")
    this.resetForm()
  }

  resetForm() {
    this.form = {
      id: "",
      comment: "",
    }
  }
}
