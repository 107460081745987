var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Peraltee Points"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h4',{staticClass:"font-weight-regular mt-1",staticStyle:{"color":"#8c8c8c"}},[_vm._v(" Peraltee points overview and claims summary. ")])]},proxy:true}])},[(_vm.peralteeMetrics)?_c('section',{staticClass:"py-4 mb-4"},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.peralteeMetrics),function(metric,idx){return _c('AppMetricCard',{key:idx,attrs:{"metric":metric}})}),1)]):_vm._e(),_c('section',[_c('span',{staticClass:"table-heading"},[_vm._v("Peraltee Claims")]),_c('Table',{staticClass:"my-6",attrs:{"headers":_vm.headers,"query":_vm.peralteeClaimsQuery,"queryKey":"consultantPeralteeClaims","searchFilters":[
        {
          field: 'search',
          label: 'Search',
        },
      ],"listFilters":[
        {
          field: 'state',
          label: 'Status',
          filters: {
            PENDING: 'Pending',
            APPROVED: 'Approved',
            REJECTED: 'Rejected',
            CANCELLED: 'Cancelled',
          },
        },
        {
          field: 'jobTitleId',
          label: 'Role',
          icon: 'la-briefcase',
          queryFilter: {
            query: _vm.allJobTitlesQuery,
            itemVaue: 'id',
            listKey: 'jobTitles',
            per: 50,
            returnObject: true,
          },
        },
      ],"dateFilters":[
        {
          field: 'createdAtDate',
          label: 'Date',
        },
      ]},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.consultant`,fn:function({ item }){return [_c('div',{staticClass:"d-flex py-4"},[_c('user-avatar',{attrs:{"user":{
              avatar: item.consultant.avatar || null,
              lastName: item.consultant.lastName,
              firstName: item.consultant.firstName,
            },"size":50}}),_c('div',{staticClass:"d-flex flex-col ml-3"},[_c('div',{},[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v(_vm._s(item.consultant.name))])]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px","font-weight":"400","color":"#8c8c8c"}},[_vm._v(_vm._s(item.consultant.jobTitle.name))])])],1)]}},{key:`item.supportService.name`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.supportService.name || "-"))])]}},{key:`item.description`,fn:function({ item }){return [_c('ExpandableTextBlock',{attrs:{"limit":150}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:`item.supportService.points`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.supportService.points || "-"))])]}},{key:`item.state`,fn:function({ item }){return [_c('state-chip',{staticClass:"text-capitalize",staticStyle:{"margin-left":"0 !important"},attrs:{"state":item.state}})]}},{key:`item.comment`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.comment || "-"))])]}}],null,true)}),_c('Modal',{model:{value:(_vm.showClaimDetail),callback:function ($$v) {_vm.showClaimDetail=$$v},expression:"showClaimDetail"}},[_c('peraltee-claim-detail',{attrs:{"claim":_vm.selectedClaim},on:{"close":_vm.closeClaimDetail,"refetch":_vm.refetchStatistics}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }